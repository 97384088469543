import { memo } from 'react';
import styled from '@emotion/styled';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { Button, Indicator } from '@mantine/core';
import { ConditionAsiajye } from './component/conditionAsiajye';
import { ConditionLong } from './component/conditionLong';
import { ConditionShort } from './component/conditionShort';
import { ConditionStrong } from './component/conditionStrong';
import { ConditionWatchlist } from './component/conditionWatchlist';
import { ConditionPro } from './component/conditionPro';
import { WatchlistAddButton } from './component/watchlistAddButton';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { LogoCard } from './component/logoCard';
import { css } from '@emotion/react';
import { ConditionChange } from './component/conditionChange';
export const asiajyeStore = proxy({
    conditions: 'asiajye',
    displayLogo: true,
    showProAllSymboll: false,
});
export const AsiajyeStock_Sidebar = memo(function AsiajyeStock_Sidebar() {
    const conditionState = useSnapshot(asiajyeStore).conditions;
    const me = useSnapshot(fr_me);
    const tutorialProduct = me?._ourMe?.subscriptions.find(s => s.agentName === 'asiajye' && s.productName === 'web_pro');
    const proUser = tutorialProduct?.productName === 'web_pro';
    const selectedColor = 'red';
    const defaultColor = 'gray';
    return (<styleds.container>
      <styleds.logoContent>
        <LogoCard />
      </styleds.logoContent>
      <styleds.buttonGroupContent css={css `
          border-bottom: 1px solid #cccccc;
        `}>
        <Button css={css `
            padding-left: 6px;
            padding-right: 6px;
          `} color={conditionState === 'asiajye' ? selectedColor : defaultColor} onClick={() => (asiajyeStore.conditions = 'asiajye')}>
          杰帥精選標的
        </Button>
        <Button css={css `
            padding-left: 6px;
            padding-right: 6px;
          `} color={conditionState === 'change' ? selectedColor : defaultColor} onClick={() => (asiajyeStore.conditions = 'change')}>
          波段精選飆股
        </Button>
        <Indicator color='red' position='top-start' radius='md' size={13} label='熱'>
          <Button css={css `
              padding-left: 6px;
              padding-right: 6px;
            `} color={conditionState === 'pro' ? selectedColor : 'yellow'} onClick={() => (asiajyeStore.conditions = 'pro')} disabled={proUser ? false : true}>
            親帶會員專屬
          </Button>
        </Indicator>
      </styleds.buttonGroupContent>
      <styleds.buttonGroupContent>
        <Button compact color={conditionState === 'strong' ? selectedColor : defaultColor} onClick={() => (asiajyeStore.conditions = 'strong')}>
          強勢飆股
        </Button>
        <Button compact color={conditionState === 'long' ? selectedColor : defaultColor} onClick={() => (asiajyeStore.conditions = 'long')}>
          最新飆股
        </Button>
        <Button compact color={conditionState === 'short' ? selectedColor : defaultColor} onClick={() => (asiajyeStore.conditions = 'short')}>
          空頭飆股
        </Button>
        <Button compact color={conditionState === 'watchlist' ? selectedColor : defaultColor} onClick={() => (asiajyeStore.conditions = 'watchlist')}>
          自選清單
        </Button>
      </styleds.buttonGroupContent>
      <styleds.watchlistAddContent>
        <WatchlistAddButton />
      </styleds.watchlistAddContent>
      <styleds.symbolListContent>
        {conditionState === 'asiajye' && <ConditionAsiajye />}
        {conditionState === 'change' && <ConditionChange />}
        {conditionState === 'pro' && <ConditionPro />}
        {conditionState === 'strong' && <ConditionStrong />}
        {conditionState === 'long' && <ConditionLong />}
        {conditionState === 'short' && <ConditionShort />}
        {conditionState === 'watchlist' && <ConditionWatchlist />}
      </styleds.symbolListContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100vh - 0px);
    padding: 4px;
    gap: 4px;
    border-right: 1px solid #dcdfe6;
  `,
    displayContent: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
  `,
    logoContent: styled.div `
    ${fill_vertical_all_center};
    height: 124px;
  `,
    buttonGroupContent: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    height: 40px;
    // background-color: #dedede;
    // border-radius: 4px;
  `,
    watchlistAddContent: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
  `,
    symbolListContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 256px);
    //background-color: #eaeaea;
    border-radius: 4px;
  `,
};
