import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { meCheckHandlerAgentWebPro } from '~/modules/SDK/me/meCheckHandlerAgentWeb';
import { asiajye_strategiesGroup } from '~/pages/asiajye/_private/asiajye_initStrategies';
import styleds from '~/pages/asiajye/_private/styleds';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import col_FaviconTitle from '~/pages/heineken_template/_col/col_FaviconTitle';
import col_LineContact from '~/pages/heineken_template/_col/col_LineContact';
import col_SoundSwitch from '~/pages/heineken_template/_col/col_SoundSwitch';
import { store } from '~/pages/heineken_template/_private/store';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { userkd } from '~/trades/indicators/asiajye/userkd';
import { usermacd } from '~/trades/indicators/asiajye/usermacd';
import { asiajyeStore } from './asiajyeStore';
export const Asiajye_Sidebar = memo(function Sidebar() {
    const charting = useSnapshot(store.charting);
    const agentState = useSnapshot(asiajyeStore);
    const entryPrice = useIndicatorStore(state => state.entryPrice);
    const marketPosition = useIndicatorStore(state => state.marketPosition) || 0;
    const marketPositionDescr = useMemo(() => {
        const isMainSelected = asiajye_strategiesGroup.main.find(config => config.displayName === charting.strategySelected.displayName);
        const isTurningSelected = asiajye_strategiesGroup.turning.find(config => config.displayName === charting.strategySelected.displayName);
        if (isMainSelected && marketPosition === 1)
            return '進場做多';
        if (isMainSelected && marketPosition === -1)
            return '進場做空';
        if (isTurningSelected && marketPosition === 1)
            return '預掛做多';
        if (isTurningSelected && marketPosition === -1)
            return '預掛做空';
    }, [marketPosition, charting.strategySelected]);
    return (<styleds.Sidebar>
      <styleds.Column>
        <col_FaviconTitle.Display />
      </styleds.Column>

      <styleds.Column>
        <ChartingServerSwitch charting={store.charting}/>
      </styleds.Column>

      <styleds.Column>
        <col_LineContact.Display src='asiajye_stock/line.png' displayTitle='大探極專業團隊' displayLineID='@a957'/>
      </styleds.Column>

      <styleds.Column>
        <col_SoundSwitch.Display />
      </styleds.Column>

      <styleds.Column>
        <styleds.positionStatus.Area>
          <styleds.positionStatus.Title>
            {charting.strategySelected.displayName}
          </styleds.positionStatus.Title>

          <styleds.positionStatus.Price>
            <div>{marketPositionDescr}</div>
            <div>{entryPrice}</div>
          </styleds.positionStatus.Price>
        </styleds.positionStatus.Area>
      </styleds.Column>

      <styleds.Column>
        <styleds.ButtonMenu>
          {/* 主要策略集 */}
          {asiajye_strategiesGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            const isProUserOnly = config.displayName.includes('特別會員限定');
            const isProPermission = meCheckHandlerAgentWebPro.check();
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    if (isProUserOnly && !isProPermission)
                        return;
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
                {config.displayName}
              </FuiButton.Display>);
        })}

          {/* 短線策略集 */}
          {asiajye_strategiesGroup.turning.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
                {config.displayName}
              </FuiButton.Display>);
        })}

          {/* 圖表做單方向 */}
          <styleds.ButtonMenu css={css `
              ${flex.h.allCenter};
              padding-bottom: 16px;
            `}>
            <FuiButton.Display variant='long' active={agentState.turningTrade.turningMP === 1} onClick={event => {
            asiajyeStore.turningTrade.turningMP = 1;
            asiajyeStore.turningTrade.position = 1;
            store.charting.updateFromState();
        }}>
              多
            </FuiButton.Display>

            <FuiButton.Display variant='short' active={agentState.turningTrade.turningMP === -1} onClick={event => {
            asiajyeStore.turningTrade.turningMP = -1;
            asiajyeStore.turningTrade.position = -1;
            store.charting.updateFromState();
        }}>
              空
            </FuiButton.Display>
          </styleds.ButtonMenu>

          {/* 單獨性指標功能 */}
          <styleds.AppendIndicatorsFuiButton indicators={[userkd]}>
            KD指標
          </styleds.AppendIndicatorsFuiButton>

          <styleds.AppendIndicatorsFuiButton indicators={[usermacd]}>
            MACD指標
          </styleds.AppendIndicatorsFuiButton>

          <styleds.AppendIndicatorsFuiButton indicators={[userkd, usermacd]}>
            KD+MACD指標
          </styleds.AppendIndicatorsFuiButton>

          <FuiButton.Display variant='long' active onClick={event => {
            store.charting.removeIndicators([userkd, usermacd]);
        }}>
            移除指標
          </FuiButton.Display>
        </styleds.ButtonMenu>
      </styleds.Column>
    </styleds.Sidebar>);
});
