import { css } from '@emotion/react';
import { store } from '~/pages/heineken_template/_private/store';
export const weng888_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#5aF502',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff164E',
        'mainSeriesProperties.candleStyle.downColor': '#5aF502',
        'mainSeriesProperties.candleStyle.upColor': '#ff164E',
        'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#2a2d37',
        'paneProperties.horzGridProperties.color': '#2a2d37',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#151720',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#eeeeee',
        'scalesProperties.lineColor': '#31353e',
    };
    templateProps.globalCssset = css `
    #__body {
    }
  `;
};
