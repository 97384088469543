import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const tsi1 = createIndicator({
    id: 'tsi1',
    displayName: '海期籌碼',
    enabledOn(symbol, data, channel) {
        return data?.type === channel.os_futures;
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const i = 25;
            const o = 13;
            const r = 13;
            const s = this.PineJS.Std.close(this._context);
            const a = this._context.new_var(s);
            const m = this.PineJS.Std.tsi(a, o, i, this._context);
            const c = this._context.new_var(m);
            const l = Math.round(m * 100);
            const l_array = this._context.new_var(l);
            l_array.get(0);
            l_array.get(1);
            const colorIndex = () => {
                if (l_array.get(0) >= 0 && l_array.get(1) >= 0 && l_array.get(0) >= l_array.get(1))
                    return 0;
                else if (l_array.get(0) >= 0 && l_array.get(1) >= 0 && l_array.get(0) < l_array.get(1))
                    return 1;
                else if (l_array.get(0) < 0 && l_array.get(1) >= 0 && l_array.get(0) < l_array.get(1))
                    return 2;
                else if (l_array.get(0) < 0 && l_array.get(1) < 0 && l_array.get(0) < l_array.get(1))
                    return 2;
                else if (l_array.get(0) < 0 && l_array.get(1) < 0 && l_array.get(0) >= l_array.get(1))
                    return 3;
            };
            return [l, colorIndex()];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '多方趨勢',
                    },
                    1: {
                        name: '空方趨勢',
                    },
                    2: {
                        name: '多方趨勢',
                    },
                    3: {
                        name: '空方趨勢',
                    },
                },
            },
        },
        defaults: {
            styles: {
                //--
                plot_0: {
                    transparency: 0,
                    visible: !0,
                },
                //--
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#000000',
                },
            },
            //填充區域
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#fffe00',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#cccc00',
                            width: 0,
                            style: 0,
                        },
                        2: {
                            color: '#0ce367',
                            width: 0,
                            style: 0,
                        },
                        3: {
                            color: '#0cc367',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            vol: {
                title: '海期籌碼',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [],
    },
});
