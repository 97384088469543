import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fontWeight600 } from '~/css/font';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
const styleds = {
    Sidebar: styled.div `
    width: 100%;
    height: 100%;
    padding: 8px 4px 4px 4px;
    gap: 8px;
    display: flex;
    flex-direction: column;
  `,
    Column: styled.div ``,
    /** 策略的當前部位之看版 UI */
    positionStatus: {
        Area: styled.div `
      ${flex.v.allCenter};
      width: 100%;
      height: 100px;
      border-radius: 5px;
      border: 1px solid #aaaaaa;
    `,
        Title: styled.div `
      ${flex.h.allCenter};
      width: 60%;
      height: 30px;
      color: #eeeeee;
      background-color: #0044aa;
      border-radius: 5px;
      font-size: 20px;
      ${fontWeight600};
    `,
        Price: styled.div `
      ${flex.h.allCenter};
      height: 30px;
      color: #555555; // #cc2222; // #00aa00
    `,
    },
    /** # 掛上特定指標組，掛到目前的策略組，並呈現到圖表上 */
    AppendIndicatorsFuiButton: memo(function AppendIndicatorsButton(props) {
        useSnapshot(store.charting).indicators; // sub change
        const isActive = store.charting.isIndicatorsIncludes(props.indicators);
        return (<FuiButton.Display active={isActive} onClick={event => {
                store.charting.replaceIndicators([
                    ...store.charting.strategySelected.indicators,
                    ...props.indicators,
                ]);
            }}>
        {props.children}
      </FuiButton.Display>);
    }),
    ButtonMenu: styled.div `
    ${flex.v.allCenter};
    width: 100%;
    gap: 4px;
  `,
};
export default styleds;
