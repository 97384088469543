import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const daddy960_tc1688_heikinAshiLine2 = createIndicator({
    id: 'daddy960-tc1688-heikinAshiLine2',
    displayName: 'tc1688_1-2',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const maLength = this._input(0);
            const count = this._input(1);
            const open = this.ohlc.openArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const close = this.ohlc.closeArray;
            const heikinOpen = context.new_var();
            const heikinClose = context.new_var();
            open.get(100);
            close.get(100);
            const avgOpen = (heikinOpen.get(1) + heikinClose.get(1)) / 2;
            const avgClose = (open.get(0) + high.get(0) + low.get(0) + close.get(0)) * 0.25;
            const ma = this.average(close, maLength);
            if (isNaN(avgOpen)) {
                heikinOpen.set(open.get(0 + 1));
            }
            else {
                heikinOpen.set(avgOpen);
            }
            if (isNaN(avgClose)) {
                heikinClose.set(close.get(0 + 1));
            }
            else {
                heikinClose.set(avgClose);
            }
            const heikinOpen_ = this.PineJS.Std.sma(heikinOpen, count, this._context);
            const heikinClose_ = this.PineJS.Std.sma(heikinClose, count, this._context);
            const colorIndex = heikinClose_ > heikinOpen_ ? 0 : 1;
            return [ma, heikinClose_, heikinClose_, colorIndex];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                movAvg: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: '#00ffff',
                },
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: '#b3daf9',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: '#ffffff',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#db5079',
                            width: 4,
                            style: 0,
                        },
                        1: {
                            color: '#5ea3ee',
                            width: 4,
                            style: 0,
                        },
                    },
                },
            },
            inputs: { maLength: 100, count: 55 },
        },
        plots: [
            {
                id: 'movAvg',
                type: 'line',
            },
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'colorer',
                target: 'plot_1',
                palette: 'palette_0',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        styles: {
            movAvg: {
                title: 'ma',
                histogramBase: 0,
                joinPoints: false,
            },
            plot_0: {
                title: 'Candles',
                histogramBase: 0,
                joinPoints: false,
            },
            plot_1: {
                title: 'Candles',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        ohlcPlots: {
            plotcandle_0: {
                title: 'Candles',
            },
        },
        is_price_study: true,
        inputs: [
            {
                id: 'maLength',
                name: 'Mov Avg Length',
                defval: 100,
                type: 'integer',
                min: 1,
                max: 2e3,
            },
            {
                id: 'count',
                name: 'count',
                defval: 55,
                type: 'integer',
                min: 0,
                max: 60,
            },
        ],
        scriptIdPart: '',
    },
});
