import dayjs from 'dayjs';
import { SessionType } from '~/modules/SDK/Chart2/SessionType';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument';
import { south_store } from '~/pages/south/south_store';
export const south_signal = createIndicator({
    displayName: '股期訊號',
    id: '股期訊號',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //#region Chart info
            const symbol = this._context.symbol.ticker;
            const sessions = fr_instrument.getSessions(symbol);
            const time = this.timeArray;
            const position = this._context.new_var();
            const entryPriceLong = this._context.new_var();
            const stoplossPriceLong = this._context.new_var();
            const entryPriceShort = this._context.new_var();
            const stoplossPriceShort = this._context.new_var();
            const close = this.ohlc.closeArray;
            const sessionType = symbol === 'TX-1'
                ? SessionType.TAIFEX
                : symbol.includes('-')
                    ? SessionType.TAIFEX_AM
                    : SessionType.TWSE;
            time.get(100);
            position.get(100);
            entryPriceLong.get(100);
            stoplossPriceLong.get(100);
            entryPriceShort.get(100);
            stoplossPriceShort.get(100);
            close.get(100);
            if (isNaN(position.get(0)))
                position.set(0);
            if (isNaN(entryPriceLong.get(0)))
                entryPriceLong.set(0);
            if (isNaN(stoplossPriceLong.get(0)))
                stoplossPriceLong.set(0);
            const thisDateNeverTrigger = new Date();
            thisDateNeverTrigger.setHours(15, 0, 0);
            let entryFlagLong = 0;
            let exitFlagLong = 0;
            let stoplossFlagLong = 0;
            let entryFlagShort = 0;
            let exitFlagShort = 0;
            let stoplossFlagShort = 0;
            //#endregion
            //#region ------------------------- Get data from agent store -------------------------
            if (south_store.symbol === '' ||
                !symbol.includes('-') ||
                (!south_store.signals.toSymbols().includes('FITX*1') &&
                    (symbol === 'TX-1' || symbol === 'TXAM-1')))
                return;
            const side = south_store.side || '';
            const entryTime = dayjs(south_store.entryTime) || dayjs(thisDateNeverTrigger);
            //#endregion
            //#region Logic of position
            if (entryTime >= dayjs(time.get(1)) && entryTime <= dayjs(time.get(0))) {
                position.set(side === 'Long' ? 1 : -1);
            }
            if (this.isSessionLastBar(sessionType)) {
                position.set(0);
            }
            //#endregion
            //#region Logic of flags
            if (position.get(1) === 0 && position.get(0) > 0) {
                entryFlagLong = 1;
                entryPriceLong.set(south_store.entryPrice);
                stoplossPriceLong.set(south_store.stoplossPrice);
            }
            if (position.get(1) > 0 && position.get(0) === 0) {
                exitFlagLong = close.get(0) > stoplossPriceLong.get(0) ? 1 : 0;
                stoplossFlagLong = close.get(0) < stoplossPriceLong.get(0) ? 1 : 0;
                entryPriceLong.set(0);
                stoplossPriceLong.set(0);
            }
            if (position.get(1) === 0 && position.get(0) < 0) {
                entryFlagShort = 1;
                entryPriceShort.set(south_store.entryPrice);
                stoplossPriceShort.set(south_store.stoplossPrice);
            }
            if (position.get(1) < 0 && position.get(0) === 0) {
                exitFlagShort = close.get(0) < stoplossPriceShort.get(0) ? 1 : 0;
                stoplossFlagShort = close.get(0) > stoplossPriceShort.get(0) ? 1 : 0;
                entryPriceShort.set(0);
                stoplossPriceShort.set(0);
            }
            //#endregion
            return [
                entryFlagLong,
                exitFlagLong,
                stoplossFlagLong,
                entryFlagShort,
                exitFlagShort,
                stoplossFlagShort,
                entryPriceLong.get(0) || NaN,
                stoplossPriceLong.get(0) || NaN,
                entryPriceShort.get(0) || NaN,
                stoplossPriceShort.get(0) || NaN,
            ];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'entryFlagLong', type: 'chars' },
            { id: 'exitFlagLong', type: 'chars' },
            { id: 'stoplossFlagLong', type: 'chars' },
            { id: 'entryFlagShort', type: 'chars' },
            { id: 'exitFlagShort', type: 'chars' },
            { id: 'stoplossFlagShort', type: 'chars' },
            { id: 'entryPriceLong', type: 'line' },
            { id: 'stoplossPriceLong', type: 'line' },
            { id: 'entryPriceShort', type: 'line' },
            { id: 'stoplossPriceShort', type: 'line' },
        ],
        defaults: {
            styles: {
                entryFlagLong: { color: '#FF0000', textColor: '#FF0000', transparency: 20, visible: true },
                exitFlagLong: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
                stoplossFlagLong: {
                    color: '#5FFA6D',
                    textColor: '#5FFA6D',
                    transparency: 20,
                    visible: true,
                },
                entryFlagShort: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
                exitFlagShort: { color: '#FF0000', textColor: '#FF0000', transparency: 20, visible: true },
                stoplossFlagShort: {
                    color: '#FF0000',
                    textColor: '#FF0000',
                    transparency: 20,
                    visible: true,
                },
                entryPriceLong: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: true,
                    transparency: 0,
                    visible: true,
                    color: '#FF0000',
                },
                stoplossPriceLong: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: true,
                    transparency: 0,
                    visible: true,
                    color: '#5FFA6D',
                },
                entryPriceShort: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: true,
                    transparency: 0,
                    visible: true,
                    color: '#5FFA6D',
                },
                stoplossPriceShort: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: true,
                    transparency: 0,
                    visible: true,
                    color: '#FF0000',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            entryFlagLong: {
                title: '進場點(多)',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多單進場',
            },
            exitFlagLong: {
                title: '出場點(多)',
                isHidden: false,
                location: 'AboveBar',
                char: '*',
                size: 'small',
                text: '多單出場',
            },
            stoplossFlagLong: {
                title: '停損點(多)',
                isHidden: false,
                location: 'AboveBar',
                char: '×',
                size: 'small',
                text: '多單停損',
            },
            entryFlagShort: {
                title: '進場點(空)',
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '空單進場',
            },
            exitFlagShort: {
                title: '出場點(空)',
                isHidden: false,
                location: 'BelowBar',
                char: '*',
                size: 'small',
                text: '空單出場',
            },
            stoplossFlagShort: {
                title: '停損點(空)',
                isHidden: false,
                location: 'BelowBar',
                char: '×',
                size: 'small',
                text: '空單停損',
            },
            entryPriceLong: {
                title: '進場價(多)',
                histogramBase: 0,
                joinPoints: false,
            },
            stoplossPriceLong: {
                title: '停損價(多)',
                histogramBase: 0,
                joinPoints: false,
            },
            entryPriceShort: {
                title: '進場價(空)',
                histogramBase: 0,
                joinPoints: false,
            },
            stoplossPriceShort: {
                title: '停損價(空)',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
