import { css } from '@emotion/react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const weng888Stock_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#5aF502',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff164E',
        'mainSeriesProperties.candleStyle.downColor': '#5aF502',
        'mainSeriesProperties.candleStyle.upColor': '#ff164E',
        'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#2a2d37',
        'paneProperties.horzGridProperties.color': '#2a2d37',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#151720',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#eeeeee',
        'scalesProperties.lineColor': '#31353e',
    };
    templateProps.globalCssset = css `
    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 5px;
        height: 32px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #2b2f45;
        border: 1px solid #555555;
        &:hover {
          background-color: #202a38;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #588a83;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #4ea49a;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
    }
  `;
};
