import { winner_tsea_ma } from '~/trades/indicators/winner/winner_tsea_ma';
import { winner_tsea_kd } from '~/trades/indicators/winner/winner_tsea_kd';
import { winner_tsea_volume } from '~/trades/indicators/winner/winner_tsea_volume';
import { winner_tx_ma } from '~/trades/indicators/winner/winner_tx_ma';
import { winner_tx_kbar } from '~/trades/indicators/winner/winner_tx_kbar';
import { winner_tx_trend } from '~/trades/indicators/winner/winner_tx_trend';
import { winner_tx_bisa } from '~/trades/indicators/winner/winner_tx_bisa';
import { winner_tx_big } from '~/trades/indicators/winner/winner_tx_big';
import { winner_os_ma } from '~/trades/indicators/winner/winner_os_ma';
import { winner_os_kbar } from '~/trades/indicators/winner/winner_os_kbar';
import { winner_os_chips } from '~/trades/indicators/winner/winner_os_chips';
import { winner_os_bisa } from '~/trades/indicators/winner/winner_os_bisa';
import { winner_os_powerline } from '~/trades/indicators/winner/winner_os_powerline';
import { winner_tx_powerline } from '~/trades/indicators/winner/winner_tx_powerline';
import { winner_tx_powerline2 } from '~/trades/indicators/winner/winner_tx_powerline2';
import { winner_all_diamond } from '~/trades/indicators/winner/winner_all_diamond';
import { winner_tx_priceline618 } from '~/trades/indicators/winner/winner_tx_priceline618';
import { winner_tx_priceline309 } from '~/trades/indicators/winner/winner_tx_priceline309';
import { winner_test_ti } from '~/trades/indicators/winner/winner_test_ti';
import { winner_test_bs0 } from '~/trades/indicators/winner/winner_test_bs0';
import { winner_test_bsti } from '~/trades/indicators/winner/winner_test_bsti';
import dayAPI from '~/utils/dayAPI';
import { store } from '~/pages/heineken_template/_private/store';
const tx_indicators_default1 = [winner_tsea_ma, winner_tsea_volume, winner_tsea_kd];
const tx_indicators_default2 = [
    winner_tx_bisa,
    winner_tx_powerline,
    winner_tx_powerline2,
    winner_tx_kbar,
    winner_tx_priceline618,
    winner_tx_priceline309,
    winner_tx_ma,
    winner_tx_trend,
    winner_test_bsti,
    winner_tx_big,
    winner_all_diamond, //鑽石
];
const os_indicators_default1 = [
    winner_os_powerline,
    winner_os_kbar,
    winner_os_bisa,
    winner_os_ma,
    winner_tx_trend,
    winner_os_chips,
    winner_all_diamond, //鑽石
];
const power_default = [winner_test_ti, winner_test_bs0, winner_test_bsti];
export const winnerA_initStrategies = () => {
    store.charting.initStrategy({
        configs: [
            {
                displayName: '台指籌碼',
                interval: '5',
                symbol: 'TX-1',
                indicators: [...power_default],
                calcFrom: dayAPI().subtract(14, 'day'),
            },
            {
                displayName: '台指期',
                interval: '5',
                symbol: 'TX-1',
                indicators: [...tx_indicators_default2],
                calcFrom: dayAPI().subtract(14, 'day'),
            },
            {
                displayName: '加權指數',
                interval: '5',
                symbol: 'TSEA',
                indicators: [...tx_indicators_default1],
                calcFrom: dayAPI().subtract(14, 'day'),
            },
            {
                displayName: 'A50',
                interval: '5',
                symbol: 'CN-1',
                indicators: [...os_indicators_default1],
                calcFrom: dayAPI().subtract(7, 'day'),
            },
            {
                displayName: '小日經',
                interval: '5',
                symbol: 'NK-1',
                indicators: [...os_indicators_default1],
                calcFrom: dayAPI().subtract(7, 'day'),
            },
            {
                displayName: '小那斯達克',
                interval: '5',
                symbol: 'NQ-1',
                indicators: [...os_indicators_default1],
                calcFrom: dayAPI().subtract(7, 'day'),
            },
            {
                displayName: '小道瓊',
                interval: '5',
                symbol: 'YM-1',
                indicators: [...os_indicators_default1],
                calcFrom: dayAPI().subtract(7, 'day'),
            },
            {
                displayName: '輕原油',
                interval: '5',
                symbol: 'CL-1',
                indicators: [...os_indicators_default1],
                calcFrom: dayAPI().subtract(7, 'day'),
            },
        ],
        // noDefaults: true,
    });
};
