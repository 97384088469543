import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { asiajye_initStyling } from '~/pages/asiajye/_private/asiajye_initStyling';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
//import Styled from '~/pages/win168/_private/styleds'
import { asiajye_initStrategies } from './asiajye_initStrategies';
import { Asiajye_Sidebar } from './asiajye_Sidebar';
export const asiajye_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web',
        'web_pro',
    ]);
    asiajye_initStyling(templateProps);
    asiajye_initStrategies();
    useThemeStore.setState({ theme: 'light' });
    templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/win168/login1.png'] }]}/>);
    //templateProps.layout.Charting = Styled.Charting
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        disabledLeftToolbar: true,
        enableVolumeIndicator: false,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;

    grid-template-areas:
      'Drawer1 Chart'
      'Drawer1 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = (<Preset_Topbar hideIfPcView showLeftBurger/>);
    templateProps.layout.Drawer1 = Asiajye_Sidebar;
};
