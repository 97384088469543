import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
const sounds = useSoundStore.getState();
export const shape = createIndicator({
    id: 'shape',
    displayName: '訊號',
    // enabledOn(symbol, data, channel) {
    //   return data?.type === channel.tw_futures || data?.type === channel.os_futures
    // },
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const sar0_p = 0.025;
            const sar1_p = 0.105;
            const ma_len = 80;
            const percent = 0.8;
            let i;
            const o = this.PineJS.Std.open(this._context);
            const c = this.PineJS.Std.close(this._context);
            const h = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const t = this.PineJS.Std.time(this._context);
            const hour = dayAPI(t).hour();
            const minute = dayAPI(t).minute();
            const sar0 = this.PineJS.Std.sar(sar0_p, sar0_p, sar0_p, this._context);
            const sar1 = this.PineJS.Std.sar(sar1_p, sar1_p, sar1_p, this._context);
            const nc = this._context.new_var(c);
            const nt = this._context.new_var(t);
            const npv = this._context.new_var();
            const s_sar0 = this._context.new_var(sar0);
            const s_sar1 = this._context.new_var(sar1);
            const bState = this._context.new_var();
            const bStatePluse = this._context.new_var();
            const bstateBias = this._context.new_var();
            const bDt = this._context.new_var(); //紀錄部位成立的時間
            let pv0 = NaN;
            let pv1 = NaN;
            let pv2 = NaN;
            let pv3 = NaN;
            let pv4 = NaN;
            let pv5 = NaN;
            s_sar0.get(1);
            s_sar0.get(2);
            s_sar1.get(1);
            s_sar1.get(2);
            bState.get(1);
            bState.get(2);
            bStatePluse.get(1);
            bStatePluse.get(2);
            bstateBias.get(1);
            bstateBias.get(2);
            bDt.get(0);
            bDt.get(1);
            nt.get(1);
            const ss1_time = t >= 8 && t <= 13;
            const bb0 = c > sar0;
            const bb0_trigger = bb0 && sar0 < s_sar0.get(1); //Long Entry plus
            const bb1_trigger = (c > s_sar1.get(0) && sar1 < s_sar1.get(1) && c > o && !ss1_time) ||
                (c > s_sar1.get(0) && sar1 < s_sar1.get(1));
            const ss0 = c < sar0;
            const ss0_trigger = ss0 && sar0 > s_sar0.get(1); //Short Entry
            const ss1_trigger = (c < s_sar1.get(0) && sar1 > s_sar1.get(1) && c < o && !ss1_time) ||
                (c < s_sar1.get(0) && sar1 > s_sar1.get(1)); //Short Entry plus
            const avg_c = this.PineJS.Std.sma(nc, ma_len, this._context);
            //第一次主要進場訊號----------------------------------------
            if (bb0_trigger) {
                bState.set(1);
            }
            if (ss0_trigger) {
                bState.set(-1);
            }
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv2 = NaN;
                bDt.set(t);
            }
            if (bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv2 = 1;
                bDt.set(t);
            }
            //第二次加碼進場訊號-------------------------------------------
            if (bb0 && !bb0_trigger && bb1_trigger) {
                bStatePluse.set(1);
            }
            else if (ss0 && !ss0_trigger && ss1_trigger) {
                bStatePluse.set(-1);
            }
            else {
                bStatePluse.set(0);
            }
            if (bStatePluse.get(0) === 1 && bStatePluse.get(1) !== 1) {
                pv1 = 1;
                bDt.set(t);
            }
            else if (bStatePluse.get(0) === -1 && bStatePluse.get(1) !== -1) {
                pv3 = 1;
                bDt.set(t);
            }
            /**
             * 待測試 if ( bState.get(0) === 1 && bState.get(1) !== 1 && bDt.get(0) === nt.get(1) ) {
             * bDt.set(t) } else if ( bState.get(0) === -1 && bState.get(1) !== -1 && bDt.get(0) ===
             * nt.get(1) ) { bDt.set(t) }
             */
            /** 停利不動 */
            if (nc.get(0) < avg_c * (1 + percent / 100) && nc.get(1) > avg_c * (1 + percent / 100)) {
                bstateBias.set(1);
            }
            else if (nc.get(0) > avg_c * (1 - percent / 100) &&
                nc.get(1) < avg_c * (1 - percent / 100)) {
                bstateBias.set(-1);
            }
            else {
                bstateBias.set(0);
            }
            if (bstateBias.get(0) === 1 && bstateBias.get(1) !== 1) {
                pv4 = 1;
                bDt.set(t);
            }
            else if (bstateBias.get(0) === -1 && bstateBias.get(1) !== -1) {
                pv5 = 1;
                bDt.set(t);
            }
            // clear current pv1, pv3, pv4 & pv5 if it occur 10 bars before
            for (i = 1; i <= 10; i++) {
                // if (npv.get(i)[1] === 1) {
                //   pv1 = NaN
                // }
                // if (npv.get(i)[3] === 1) {
                //   pv3 = NaN
                // }
                if (npv.get(i)[4] === 1) {
                    pv4 = NaN;
                }
                if (npv.get(i)[5] === 1) {
                    pv5 = NaN;
                }
            }
            // dont show at 8:45 - 9:00 am
            const gt = new Date(this.PineJS.Std.time(this._context));
            if (gt.getHours() === 8) {
                pv4 = NaN;
                pv5 = NaN;
            }
            const localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const kbarLoadingTimeMs = 10000;
            const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            if (isInitialized && isBarChanging) {
                if ((bState.get(1) === 1 && bState.get(2) !== 1 && bDt.get(0) === nt.get(1)) ||
                    (bStatePluse.get(1) === 1 && bStatePluse.get(2) !== 1 && bDt.get(0) === nt.get(1)) ||
                    (bstateBias.get(1) === 1 && bstateBias.get(2) !== 1 && bDt.get(0) === nt.get(1))) {
                    sounds.playSoundVictoryLoopDebounced();
                    console.log('LongEntry');
                }
                else if ((bState.get(1) === -1 && bState.get(2) !== -1 && bDt.get(0) === nt.get(1)) ||
                    (bStatePluse.get(1) === -1 && bStatePluse.get(2) !== -1 && bDt.get(0) === nt.get(1)) ||
                    (bstateBias.get(1) === -1 && bstateBias.get(2) !== -1 && bDt.get(0) === nt.get(1))) {
                    sounds.playSoundHornLoopDebounced();
                    console.log('ShortEntry');
                }
            }
            npv.set([pv0, pv1, pv2, pv3, pv4, pv5]);
            return [pv0, pv1, pv2, pv3, pv4, pv5];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        defaults: {
            styles: {
                plot_0: {
                    color: '#ff2e00',
                    textColor: '#ff2e00',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#ff2ef8',
                    textColor: '#ff2ef8',
                    transparency: 10,
                    visible: true,
                },
                plot_2: {
                    color: '#00aa00',
                    textColor: '#00aa00',
                    transparency: 20,
                    visible: true,
                },
                plot_3: {
                    color: '#00ff00',
                    textColor: '#00ff00',
                    transparency: 10,
                    visible: true,
                },
                plot_4: {
                    color: '#00d77a',
                    textColor: '#2196F3',
                    transparency: 10,
                    visible: true,
                },
                plot_5: {
                    color: '#ffbe74',
                    textColor: '#2196F3',
                    transparency: 10,
                    visible: true,
                },
            },
            inputs: {
            // in_0: 0.026,
            // in_1: 0.1,
            // in_2: 80,
            // in_3: 0.8,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'shapes',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'shapes',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'shapes',
            },
            {
                id: 'plot_5',
                type: 'shapes',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_triangle_up',
                size: 'normal',
                text: '',
                title: 'Shapes',
            },
            plot_1: {
                isHidden: false,
                location: 'BelowBar',
                char: '★️',
                size: 'small',
                title: 'Shapes',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_triangle_down',
                size: 'normal',
                text: '',
                title: 'Shapes',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '★️',
                size: 'small',
                title: 'Shapes',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_diamond',
                size: 'small',
                text: '',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_diamond',
                size: 'small',
                text: '',
                title: 'Shapes',
            },
        },
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'sar AF',
        //   defval: 0.026,
        //   type: 'float',
        //   min: 0.001,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_1',
        //   name: 'sar AF Limit',
        //   defval: 0.1,
        //   type: 'float',
        //   min: 0.001,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_2',
        //   name: '均線',
        //   defval: 80,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_3',
        //   name: '乖離',
        //   defval: 0.8,
        //   type: 'float',
        //   min: 0.1,
        //   max: 2e3,
        // },
        ],
        //inputs:[],
    },
});
